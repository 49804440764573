.actionButton button{
    margin: 10px;
}

.actionButton{
    align-self: center;
}

.title{
    width: 100%;
    text-align: center;
}