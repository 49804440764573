.card-heading-contr {
    display: flex;
    justify-content: space-between;
    min-height: 60px;
}

.card-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    color: #253514;
}

.card-heading-type {
    font-size: 12px;
    font-weight: 400;
    color: #645d57;
    line-height: 0px;
}

.card-heading-address {
    font-size: 13px;
    font-style: oblique;
    font-weight: bold;
    color: rgb(70, 43, 0);
    margin: 1px 0px;
}

.card-tick {
    border: 1px solid #52643E;
    border-radius: 50%;
    padding: 20%;
    color: #52643E;
}

.card-img {
    border-radius: 10px;
}

.card-promoters {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-variant: small-caps;
    color: #410002;
    opacity: 0.7;
    padding: 5px;
    transition: 0.3s;
    min-height: 50px;
    margin: 5px 0px 0px 0px;
    border-radius: 15px;
    text-align: center;
}

/* .card-promoters span{
    padding: 10px;
} */

.card-promoters span:hover {
    background: #FFDDB5;
}

.card-promoters-no-hover span:hover {
    background: white !important;
}

.card-info-contr {
    display: flex;
    flex-wrap: wrap;
    color: #645d57;
    width: 100%;
}

.card-info-text {
    font-weight: 700;
    font-size: 17px;
    line-height: 28px;
}

.card-info-amn-icons-cntr {
    font-weight: bold;
    font-size: large;
}

.card-info-amn-icon {
    padding: 1%;
}

.prop-card-info-left-cont {
    width: 50%;
}

.prop-card-info-right-cont {
    width: 50%;
    /* min-height: 50px; */
}

.prop-card-heading-name {
    margin: 5px 0px;
}

.prop-card-heading-bottom {
    padding-bottom: 5px;
    line-height: normal !important;
    margin: 5px 0px;
}

.prop-card-cnt {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.card-actns {
    float: right;
    padding-top: 0px !important;
}

.plot-grid {
    padding: 10px;
}

.plot-card {
    border: 1px solid #FFDDB5;
    border-radius: 16px !important;
}

.plot-skeleton-card {
    border: 1px solid #FFDDB5;
    border-radius: 16px !important;
    padding: 24px 16px;
    min-height: 450px;
}

.plot-action:not( :hover) {
    color: white;
}

.plot-action:hover {
    color: white;
}

.cart-typography {
    min-height: 64px;
}

.badge-icon {
    left: 14px;
    bottom: 8px;
}

.btn-1 {
    display: inline-block;
    padding: 0 20px !important;
    line-height: 40px !important;
    color: white;
    background-color: #ff5a3c;
    text-decoration: none;
    float: right;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
}