.RootBase-root-102 {
    display: block !important;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
    background-color: #0000FF;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #ADD8E6;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #ADD8E6;
}

.Editor-input {
    height: 35px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}