.gm-style-iw+button {
    display: none;
}

.gm-ui-hover-effect {
    display: none !important;
}

.map-pdp-popup-container {
    position: absolute;
    height: 750px;
    z-index: 9;
}

.map-pdp-popup-mobile-container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
    left: 0px;
}

.map-pdp-popup {
    height: 67%;
    width: 375px;
    background-color: #ffeedd;
    border: solid 1px #643f00 ;
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    z-index: 9;
    position: absolute;
}

.map-pdp-popup-mobile {
    height: 100%;
    width: 100%;
    background-color: #ffeedd;
    padding-top: 10%;
    z-index: 9;
    position: absolute;
}

.map-sort-button {
    position: absolute;
    z-index: 9;
}

.sortCard::-webkit-scrollbar {
    display: none;
}

.sortCard {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
