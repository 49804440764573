.pdp-map-popup-oa-close-contr {
    position: absolute;
    top: 0px;
    right: 0px;
}

.pdp-map-popup-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2px;
    font-variant: small-caps;
    color: #000000;
}

.pdp-map-popup-heading-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 2px;
    font-variant: small-caps;
    color: #000000;
}

.pdp-map-popup-sub-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2px;
    font-variant: small-caps;
    color: #000000;
}

.pdp-map-popup-contents {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #A56B00;
}

.pdp-map-popup-chip-container-orange {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 2px;
    background: #FFEEDD;
    border: 1px solid #FBDEBC;
    border-radius: 32px;
}

.pdp-map-popup-chip-container-green {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    gap: 2px;
    background: #F8FFE9;
    border: 1px solid #52643E;
    border-radius: 32px;
}

.pdp-map-popup-chip-contents {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: -0.25px;
    font-variant: small-caps;
    color: #543500;
}

.pdp-map-popup-bigs-cntr {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.pdp-map-popup-bigs-left {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.pdp-map-popup-big-vals {
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    color: #A56B00;
}

.pdp-map-popup-big-text {
    padding-top: 2px;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2px;
    font-variant: small-caps;
    color: #000000;
}

.pdp-map-popup-big-vals-unit {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #A56B00;
}

.pdp-map-popup-card-cntr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    background: #634F35;
    border-radius: 8px;
}

.pdp-map-popup-card-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 2px;
    font-variant: small-caps;
    color: #FBDEBC;
}

.pdp-map-popup-card-main-heading {
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 2px;
    color: #FFEEDD;
}