.divider-text-margin {
    margin-top: 15px !important;
}

.divider-text-margin-sm-md {
    margin: 10px !important;
}

.divider-switch-margin {
    margin-top: 5px !important;
}

.divider-text {
    font-weight: bold;
    color: #52643e;
}

.divider {
    flex-grow: 1;
    border-bottom: 1px dashed #57432b;
    margin: 0px 10px 23px 10px !important;
}