.preload {
    width: 100vw;
    height: 103vh;
    position: absolute;
    background: #ccc;
    background-image: url("../../Assets/loading_logo.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 150px;
    z-index: 1001;
    overflow: hidden;
    top: 0px;
}