.pdp-tl-circle {
    height: 18px;
    width: 18px;
    background: #FFDDB5;
    border-radius: 50%;
    border: 2px solid #E39D35;
}

.pdp-tl-vl {
    border-left: 1px solid #E39D35;
    height: 50px;
}

@media only screen and (max-width: 600px) {
    .pdp-tl-vl {
        border-left: 1px solid #E39D35;
        height: 25px !important;
    }

    .pdp-tl-txt-cntr {
        font-size: 15px !important;
        line-height: 20px !important;
    }
}

.pdp-tl-txt-cntr {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #4B4640;
}