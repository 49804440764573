.pac-container {
    z-index: 1111111;
}

.wrapper {
    position: relative;
    width: 1280px;
}

.parent {
    position: absolute;
}

.sb-filter-widget {
    position: relative;
    overflow-x: hidden;
    margin-bottom: 10px;
    border-radius: 25px;
    width: 90%;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.sb-filter__layout-details.sb-filter__layout-details--show {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: 1px solid #ff5a3c;
    border-left: 0px;
}

.sb-filter__layout-details {
    width: 350px;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 800;
    overflow: hidden;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
}

.sb-filter__map {
    width: 100%;
    height: 550px;
    position: relative;
    overflow: hidden;
    border-radius: 25px;
}

.layout-details__body {
    background-color: #fff;
    width: 100%;
    z-index: 12;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.layout-details__header {}

.layout-details__description {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.ltn__utilize-menu-head {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
    padding-bottom: 0px;
    background: #ff5a3c;
    color: #fff;
}

.ltn__utilize-menu-inner {
    padding-right: 0px;
}

.ltn__utilize-close {
    color: #fff;
}

.sb-nearby-result-item {
    border-bottom: 2px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.sb-nearby__result-title {
    font-weight: bold;
    color: #000000;
}

.sb-nearby__result-type {
    font-size: 14px;
    color: #000000;
}

.sb-nearby__result-description {
    font-size: 14px;
}

.nearbyhead {
    padding: 10px;
}

.sb-nearby__message-wrapper {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    top: auto;
    background-color: transparent;
}

.sb-nearby__message {
    max-width: 250px;
    width: 100%;
    position: absolute;
    opacity: 1;
    transform: translate(0px, 0);
    background-color: #fff;
    padding: 10px;
    left: 10px;
    right: 10px;
    box-sizing: border-box;
    top: 10px;
    font-size: 14px;
    backface-visibility: hidden;
    transition: all .3s;
    border-radius: 3px;
    min-height: 50px;
    box-shadow: 0 1px 4px 0 rgb(233 150 150 / 16%);
}

.sb-nearby__text {
    color: #000000;
}

.ltn__tab-menu-new a.active {
    color: unset !important;
    border-color: unset !important;
    border-bottom: unset !important;
}

.ltn__tab-menu-new a.active::before {
    background-color: unset !important;
    border: unset !important;
}

.ltn__tab-menu-new a.active h6 {
    color: white !important;
    background: var(--ltn__secondary-color-3) !important;
}


.ltn__tab-menu-new a h6:hover {
    color: white !important;
    background: var(--ltn__secondary-color) !important;
}

.gm-ui-hover-effect {
    /* display: none !important; */
}

.gm-style .gm-style-iw-c {
    border-radius: 10px !important;
    padding: 0px !important;
}

.gm-style-iw-d {
    overflow: unset !important;
}

.view-link {
    font-weight: bold !important;
    color: #0049ff;
    margin-top: 20px;
}

.MuiRadio-root {
    padding-right: 5px !important;
}

.filter-dialog .MuiPaper-root {
    min-height: 500px !important;
}

.gm-style-mtc,
.gm-svpc {
    display: none !important;
}

.pac-target-input {
    border: 2px solid #815301;
    border-radius: 25px;
    padding: 10px 80px 10px 15px;
    height: 25px;
    font-size: 16px;
    width: 325px;
    margin: 20px;
}

.clear-btn-cntr {
    margin-left: -90px;
    padding-top: 28px;
    width: 35px;
}

.dp-btn-cntr {
    margin-left: 0px;
    padding-top: 28px;
    width: 35px;
}

.extr-btns {
    padding: 5px !important;
}

@media only screen and (max-width: 600px) {
    .pac-target-input {
        width: auto;
    }
}

.all-dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: #9B9B9B;
    margin-right: 6px;
}

.available-dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: #7ed321;
    margin-right: 6px;
}

.sold-dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: #F13030;
    margin-right: 6px;
}

.booked-dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    background-color: #3088F1;
    margin-right: 6px;
}

.dot-text {
    padding-top: 3px;
}

/* near by popup - start */

.nb-popup-cntr {
    position: absolute;
    top: 70px;
    left: 10px;
    padding: 10px;
    height: auto;
    width: 250px;
    background-color: #ffeedd;
    border: solid 1px #643f00;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.nb-popup-close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
}

.nb-popup-place-name {
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.nb-popup-address {
    font-size: 16px;
    text-align: center;
}

.nb-popup-extr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* near by popup - end */
