.fabricMobileView::-webkit-scrollbar {
    width: 1.7em;
    height: 1.7em;
}

.fabricMobileView::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fabricMobileView::-webkit-scrollbar-thumb {
    background-color: #835400;
    outline: 1px solid #A56B00;
}

.fabricMobileView {
    -webkit-overflow-scrolling: touch;
}

.pac-container {
    z-index: 1111111;
}

.wrapper {
    position: relative;
    width: 1280px;
}

.parent {
    position: absolute;
}

.sb-filter-widget {
    position: relative;
    overflow-x: hidden;
    margin-bottom: 10px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.sb-filter__layout-details.sb-filter__layout-details--show {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border: 1px solid #ff5a3c;
    border-left: 0px;
}

.sb-filter__layout-details {
    width: 350px;
    background-color: #fff;
    position: absolute;
    /* top: 68px;
    right: 630px; */
    height: 95%;
    z-index: 800;
    overflow: hidden;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
}

.sb-filter__map {
    width: 100%;
    height: 550px;
    position: relative;
    overflow: hidden;
}

.layout-details__body {
    background-color: #fff;
    width: 100%;
    z-index: 12;
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.layout-details__header {}

.layout-details__description {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}

.ltn__utilize-menu-head {
    padding: 10px;
    margin-bottom: 15px;
    background: #ff5a3c;
    color: #fff;
}

.ltn__utilize-menu-inner {
    padding-right: 0px;
}

.ltn__utilize-close {
    color: #fff;
    float: right;
    background: red;
    border: 1px solid;
    cursor: pointer;
}

.sb-nearby-result-item {
    border-bottom: 2px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.sb-nearby__result-title {
    font-weight: bold;
    color: #000000;
}

.sb-nearby__result-type {
    font-size: 14px;
    color: #000000;
}

.sb-nearby__result-description {
    font-size: 14px;
}

.nearbyhead {
    padding: 10px;
}

.sb-nearby__message-wrapper {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 900;
    top: auto;
    background-color: transparent;
}

.sb-nearby__message {
    max-width: 250px;
    width: 100%;
    position: absolute;
    opacity: 1;
    transform: translate(0px, 0);
    background-color: #fff;
    padding: 10px;
    left: 10px;
    right: 10px;
    box-sizing: border-box;
    top: 10px;
    font-size: 14px;
    backface-visibility: hidden;
    transition: all .3s;
    border-radius: 3px;
    min-height: 50px;
    box-shadow: 0 1px 4px 0 rgb(233 150 150 / 16%);
}

.sb-nearby__text {
    color: #000000;
}

.toolTip {
    position: absolute;
    z-index: 1;
    /* background: blue; */
    padding: 8px;
    font-size: 13px;
    color: black;
    visibility: hidden;
    /* border-radius: 20px;*/
    pointer-events: none;
}

.tooltip-container {
    width: 350px;
    height: auto;
    background-color: #fff;
    position: absolute;
    top: 14px;
    right: 4px;
    border: 1px solid #0d6efd;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 16%);
    left: 20px;
}

.tooltip-header {
    background-color: #fff;
    width: 100%;
    position: relative;
    height: 100%;
    align-items: center;
    background: var(--ltn__secondary-color);
    /* margin: 3px; */
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tooltip-header h3 {
    font-size: 18px;
    text-align: center;
    padding: 10px;
    color: white;
}

.tooltip-body {
    padding: 10px;
}

.split-para {
    font-size: 16px;
    color: black;
    font-weight: bolder;
    margin-bottom: 4px;
}

.split-para span {
    float: right;
    font-size: 13px;
    font-weight: 600;
}

.mainmap .gmnoprint {
    /* display: none !important; */
}

.gm-style-mtc {
    /* display: none !important; */
}

.price-overlay {
    color: #FF5A3C;
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
}

.filterGroup{
    /* display: none !important; */
    display: flex !important;
    box-shadow: none !important;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: initial !important;
}

.filterGroup > button{ 
    margin-top: 5px !important;
    margin-left: 8px;
    margin-bottom: 5px !important;
    min-width: 170px !important;
    max-height: 60px !important;
}

.filterGroupMenu{
    /* display: none !important; */
    display: flex !important;
    box-shadow: none !important;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: initial !important;
}

.filterGroupMenu > button{ 
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    min-width: 170px !important;
    max-height: 60px !important;
}