.view {
    display: flex;
}

.App .SideNav {
    max-height: 100vh;
    padding-top: 60px;
}

main {
    flex-grow: 1;
    overflow: auto;
    height: 100vh;
}

.mainContent {
    padding: 75px 10px 0 !important;
}

.menuIcon .MuiSvgIcon-root {
    font-size: 20px !important;
}

.Mui-selected .menuIcon,
.Mui-selected .MuiListItemText-root {
    color: #3f51b5 !important;
}

.menuText .MuiTypography-root {
    font-size: 0.875rem;
}

.MuiListItem-gutters {
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.MuiListItemIcon-root {
    min-width: 30px !important;

}

.grid-header-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bolder;
    color: blue;
    margin-bottom: 20px !important;
    
}

.grid-bottom-border{
    margin-bottom: 35px !important;
}