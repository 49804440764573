.pdp-verified-cntr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 2px;
    background: #F8FFE9;
    border: 1px solid #52643E;
    box-shadow: 0px -10px 16px rgb(0 0 0 / 10%);
    border-radius: 32px;
}

.pdp-verified-tick {
    color: #52643E;
}

.pdp-layout-diagram-img {
    width: 75%;
    cursor: pointer;
}

.pdp-verified-txt {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    font-feature-settings: "smcp";
    font-variant: small-caps;
    color: #52643E;
}

.pdp-prj-by {
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.25px;
    font-variant: small-caps;
    color: #462B00;
    display: inline;
}

.pdp-prj-name {
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.25px;
    background: linear-gradient(92.69deg, #5C6D4A 1.33%, #253514 98.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.pdp-prj-address {
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.25px;
    background: linear-gradient(92.69deg, black 1.33%, black 98.29%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.pdp-tlt-button {
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border-color: wheat !important;
    text-transform: none !important;
}

.pdp-lo-button-grp {
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border: 1px solid #A56B00 !important;
    text-transform: none !important;
}

.pdp-lo-button-grp-clckd {
    background-color: white !important;
    color: #835400 !important;
    border: 1px solid #A56B00 !important;
    text-transform: none !important;
}

.pdp-lo-button-grp-fav {
    background-color: darkgray !important;
    color: white !important;
    border: 1px solid white !important;
    text-transform: none !important;
}

.pdp-lo-button-grp-disable {
    background-color: #ff5a3c !important;
    color: white !important;
    border: 1px solid white !important;
    text-transform: none !important;
}

.active-tab {
    background-color: #FFF8F4 !important;
}

.active-available-tab {
    background-color: #99BE75 !important;
}

.active-sold-tab {
    background-color: #E47A87 !important;
}

.active-booked-tab {
    background-color: #3088F1 !important;
}

.slider-left,
.slider-right {
    margin-top: 30%;
}

.slider-left div,
.slider-right div {
    border: none !important;
    color: #1c1b1f !important;
}

.pdp-about-size {
    font-family: 'Roboto Serif';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #52643E;
}

.pdp-tl-about-company {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.25px;
    color: #000000;
    padding: 20px;
    overflow: scroll;
    max-height: 500px;
    text-align: justify !important;
    box-sizing: content-box;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.pdp-tl-about-company::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.pdp-tl-about-company-rm {
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    text-align: center;
}

.pdp-lo-dtls-txt {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: -0.25px;
    font-variant: small-caps;
    color: #4B4640;
    text-align: center;
}

.pdp-amen-card-cntr {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 150px;
    background: #FFF8F4;
    border: 1px solid #645D57;
    border-radius: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
    gap: 20px;
}

.pdp-amen-card-icon {
    transform: scale(4);
    color: #645D57;
}

.pdp-amen-card-txt {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: #685C50;
    text-align: center;
}

.pdp-contact-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.pdp-contact-card-nme {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #1F1B16;
}

.pdp-contact-card-cnt {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.25px;
    color: #835400;
}

.pdp-footer-address {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.25px;
    color: #000000;
}

.pdp-footer-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: justify;
    letter-spacing: -0.25px;
    color: #000000;
}

.pdp-bnk-logo {
    height: 30px;
}

.pdp-bnk-contr {
    display: flex;
    direction: row;
    gap: 10px;
}

.otp-login {
    padding-top: 64px;
    padding-bottom: 16px;
    padding-left: 32px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pt-50 {
    padding-top: 50px;
}

.container {
    max-width: 90%;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}

.mobile-label {
    font-size: 20px;
    margin: 0px;
    margin-bottom: 12px;
}

.mobile-span {
    font-size: 14px;
}

.accordion-div {
    background-color: #fff8f4 !important;
    border: 1px solid #645D57;
    border-radius: 12px !important;
    width: 80%;
}

.accordion-summary,
.accordion-summary span {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-weight: bold !important;
    font-size: 18px !important;
}

.accordion-summary p,
.accordion-details {
    font-family: 'Roboto' !important;
    font-style: normal;
    font-size: 16px !important;
}

.accordion-div::before {
    background-color: transparent !important;
}

/* basic positioning */
.legend {
    list-style: none;
}

.legend li {
    float: left;
    margin-right: 10px;
    padding: 10px;
    border-radius: 8px;
    gap: 10px;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: white;
    min-width: 120px;
    text-transform: uppercase;
    text-align: center;
    border: 3px solid transparent;
}

/* your colors */
.legend .all,
.li-all {
    background-color: #9B9B9B;
}

.legend .available,
.li-available {
    background-color: #7ed321;
}

.legend .sold,
.li-sold {
    background-color: #F13030;
}

.legend .booked,
.li-booked {
    background-color: #3088F1;
}

.legend .active {
    background-color: whitesmoke !important;
    color: #835400 !important;
    border: 3px solid #FFDDB5 !important;
}

.mobileView {
    padding: 0px;
    display: grid;
    gap: 10px;
    grid-auto-flow: column dense;
    grid-template-rows: 50px 50px;
}

@media only screen and (max-width: 600px) {
    .pdp-about-size {
        font-size: 30px !important;
    }

    .pdp-tl-about-company {
        padding-top: 25px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        text-align: justify !important;
    }

    .headerImage {
        height: 110px !important;
    }

    .pdp-prj-by {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .pdp-prj-name {
        font-size: 30px !important;
    }

    .pdp-prj-address {
        font-weight: 500 !important;
        font-size: 18px !important;
    }

    .pdp-footer-desc {
        width: 100% !important;
        padding: 15px !important;
    }

    .pdp-amen-card-cntr {
        width: 110px !important;
        height: 130px !important;
    }

    .pdp-amen-card-cntr>img {
        width: 60px !important;
    }

    .pdp-amen-card-cntr>span {
        font-size: 13px !important;
    }

    .pdp-contact-card {
        max-width: 110px !important;
    }

    .pdp-contact-card>div:first-child{
        width: 85px !important;
        height: 85px !important;
    }

    .pdp-contact-card-nme {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    .pdp-contact-card-cnt {
        font-size: 15px !important;
        line-height: 20px !important;
    }

    .pdp-contact-card>button {
        font-size: 12px !important;
    }

    .pdp-tlt-button {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}