.prefmain-header {
    padding: 10px 0px 10px 25px;
    text-align: center;
}
.prefmain-cnt {
    font-size: 26px;
    font-weight: 500;
}
.prefmain-desc {
    font-size: 22px;
    font-weight: bold;
}
span {
    text-transform: capitalize;
}