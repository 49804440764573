.login-inputWidth {
    width: 70%;
}

.login-form-heading {
    font-weight: bold;
    color: #835400;
    font-size: x-large;
}

.login-form-content {
    color: #835400;
}

.login-button {
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border-color: wheat !important;
    text-transform: none !important;
}

.login-icon-button {
    background-color: #ffddb5 !important;
    color: #835400 !important;
    border-color: black !important;
    margin: 1% !important;
}

.login-form-div {
    margin-top: 3%;
    margin-bottom: 3%;
}

.MuiFormHelperText-root {
    cursor: pointer !important;
}

.googleContainer {
    background-color: #4285f4;
    border: none;
    color: #fff;
    height: 50px;
    width: 240px;
    padding: 0px;
    margin: 40px;
}

@media only screen and (max-width: 600px) {
    .googleContainer {
        margin: 20px;
    }
}

.googleContentWrapper {
    border: 1px solid transparent;
    cursor: pointer;
}

.googleButtonIcon {
    padding: 15px;
    background-color: #fff;
    border-radius: 1px;
    float: left;
}

.googleButtonIconImage {
    width: 18px;
    height: 18px;
}

.googleButtonContents {
    font-weight: 500;
    letter-spacing: .21px;
    margin-left: 20px;
    margin-right: 6px;
    vertical-align: top;
    font-size: 16px;
    line-height: 48px;
}

.forgot-span{
    cursor: pointer;
    text-decoration: underline;
    color: gray;
    margin-top: 10px;
    font-size: 12px;
}