body {
  font-family: 'roboto';
  background-color: #ffeedd;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 10px;
  flex-direction: column;
  padding-bottom: 20px;
  text-align: center;
}

.social-media a svg {
  color: #805200;
  font-size: 35px;
  padding: 0px 10px 0px 10px;
}

footer a {
  text-decoration: none;
  color: black;
  font-size: 17px;
}