.padding-span {
    padding: 0 2px 0 2px;
}
.pref-grid {
    padding: 10px;
}

.pref-card {
    border: 1px solid #FFDDB5 !important;
    border-radius: 16px !important;
}
span {
    text-transform: capitalize;
}
.pref-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    /* padding: 0 50px; */
}
.pref-content-budget {
    display: inline-flex;
    gap: 5px;
    background-color: #ffebc9;
    width: 100%;
    min-height: 45px;
    align-items: center;
    color: #000;
}
.pref-content-direction {
    display: inline-flex;
    gap: 5px;
    background-color: #ffd896;
    width: 100%;
    min-height: 45px;
    align-items: center;
    color: #000;
}
.pref-action-icon {
    width: 25px;
}
.pref-content-img {
    padding: 5px 15px;
}
.pref-content-area{
    background-color: #ffe3b4;
    display: inline-flex;
    color: #000 !important;
    width: 100%;
    align-items: center;
    gap: 6px;
    font-weight: 600;
}
.pref-card-cnt {
    padding: 0px !important;
}

.pref-card-actns {
    justify-content: space-around;
}

.pref-title-text {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #1f2835;
}

.pref-content-text {
    font-weight: bold;
    font-size: 18px;
    /* line-height: 22px; */
    padding: 10px 0 5px 0;
    color: #545454;
}
.pref-area-text {
    font-size: 13px;
    padding: 6px 0 4px 0;
    text-align: center;
    min-height: 40px;
    color: #545454;
}
.pref-location-icon {
    text-align: center;
}
.pref-matches-text {
    font-weight: 200;
    font-size: 18px;
    margin: auto;
    width: 65%;
    padding: 10px;
    background-color: #f2b54e;
    text-align: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
    gap: 7px;
}
.pref-match-count {
    color: #feecd9;
    background-color: #410002;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    align-items: center;
    align-content: normal;
    text-align: center;
    position: relative;
}
.pref-match-count-number { 
    position: absolute;
    top: 20%;
    left: 35%;
}
.card-heading-contr {
    display: flex;
    justify-content: space-between;
    min-height: 60px;
}

.card-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    color: #253514;
}

.card-heading-type {
    font-size: 12px;
    font-weight: 400;
    color: #645d57;
    line-height: 0px;
}

.card-heading-address {
    font-size: 13px;
    font-style: oblique;
    font-weight: bold;
    color: rgb(70, 43, 0);
    margin: 1px 0px;
}

.card-tick {
    border: 1px solid #52643E;
    border-radius: 50%;
    padding: 20%;
    color: #52643E;
}

.card-img {
    border-radius: 10px;
}

.card-promoters {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    font-variant: small-caps;
    color: #410002;
    opacity: 0.7;
    padding: 5px;
    transition: 0.3s;
    min-height: 26px;
    margin: 5px 0px 0px 0px;
    border-radius: 15px;
    text-align: center;
}

/* .card-promoters span{
    padding: 10px;
} */

.card-promoters span:hover {
    background: #FFDDB5;
}

.card-promoters-no-hover span:hover {
    background: white !important;
}

.card-info-contr {
    display: flex;
    flex-wrap: wrap;
    color: #645d57;
    width: 100%;
}


.card-info-amn-icons-cntr {
    font-weight: bold;
    font-size: large;
}

.card-info-amn-icon {
    padding: 1%;
}

.card-info-left-cont {
    width: 40%;
}

.card-info-right-cont {
    width: 60%;
    min-height: 50px;
}

.plot-skeleton-card {
    border: 1px solid #FFDDB5;
    border-radius: 16px !important;
    padding: 24px 16px;
    min-height: 450px;
}

/* .plot-action:not( :hover) {
    color: white;
}

.plot-action:hover {
    color: white;
} */

.cart-typography {
    min-height: 64px;
}

.badge-icon {
    left: 14px;
    bottom: 8px;
}

.btn-1 {
    display: inline-block;
    padding: 0 20px !important;
    line-height: 40px !important;
    color: white;
    background-color: #ff5a3c;
    text-decoration: none;
    /* float: right; */
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    border: none;
}