.loginButton {
    display: flex;
    justify-content: flex-end;
    padding: 32px 20px 0px 0px !important;
}

.gm-style-mtc, .gm-svpc {
    display: none !important;
}

.gm-style-mtc ul {
    top: 20px !important;
}

.gm-style-iw-t {
    bottom: 45px !important;
}

.banner-contr {
    display: flex;
    margin-top: 1em;
    background-color: #6A8283;
    color: white;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.banner-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5%;
    gap: 10px;
    cursor: pointer;
}
